import React, { useEffect, useState} from 'react'
import axios from 'axios'
import { BASE_URL, STUDY_ABBREVIATION_MAP } from '../../utils/constants';
import { MdExitToApp } from "react-icons/md";
import { Table, Button } from 'react-bootstrap'
import { Oval } from "react-loader-spinner"
import { useNavigate, Link } from 'react-router-dom';
import moment from 'moment';
import _ from "underscore"
import { AiFillWarning, AiFillCaretUp, AiFillCaretDown } from "react-icons/ai";


import "./PatientComplications.scss"

const PatientComplications = ({ user, logUserOut }) => {
  const [loading, setLoading] = useState(false)
  const [complicationEvents, setComplicationEvents] = useState([])
  const [sorting, setSorting] = useState({ field: 'alertDate', ascending: true })
  const navigate = useNavigate();
  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  }

  useEffect(() => {
    const getNonCompliantPatients = async () => {
      try {
        setLoading(true)
        const response = await axios.get(`${BASE_URL}/api/v1/event/all-complication-events/`, config)
        const complicationEvents = response.data.complicationEvents
        setComplicationEvents(complicationEvents)
        setLoading(false)
      }
      catch (error) {
        console.log(error)
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        if (error.response && (error.response.status === 401 || message === 'Not authorized, token failed')) {
          logUserOut()
        }
      }
    }
    getNonCompliantPatients()
  }, [])

  useEffect(() => {
    setComplicationEvents(sorting.ascending? _.sortBy(complicationEvents, sorting.field) : _.sortBy(complicationEvents, sorting.field).reverse())
  }, [sorting]);

  return (
    <div className="patient-complications">
      {loading ? (<Oval
          height={80}
          width={80}
          color="#4caeff"
          wrapperStyle={{}}
          wrapperClass="loader"
          visible={true}
          ariaLabel='oval-loading'
          secondaryColor="#4caeff"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
        ) : (
        <>
          <div className="patient-complications-header">
            <h2 className="logo"><AiFillWarning /> Patient Complications {complicationEvents.length > 0? `(${complicationEvents.length})` : ""}</h2> 
          </div>
          <div className="patient-complications-table">
            <Table striped bordered={false} hover>
              <thead>
                <tr>
                  <th className='custom-id' onClick={() => setSorting({field: "customPatientId", ascending: !sorting.ascending})}>Patient ID {sorting.field === "customPatientId"? sorting.ascending? <AiFillCaretUp/>: <AiFillCaretDown/> : ""}</th>
                  <th className='study' onClick={() => setSorting({field: "study", ascending: !sorting.ascending})}>Study {sorting.field === "study"? sorting.ascending? <AiFillCaretUp/>: <AiFillCaretDown/> : ""}</th>
                  <th className='event-date' onClick={() => setSorting({field: "eventDate", ascending: !sorting.ascending})}>Event Date {sorting.field === "eventDate"? sorting.ascending? <AiFillCaretUp/>: <AiFillCaretDown/> : ""}</th>
                  <th className='actions'>Actions</th>
                  <th className='action-buttons'></th>
                </tr>
              </thead>
              <tbody>
                {complicationEvents.length > 0 ? complicationEvents.map((complicationEvent, index) => {
                  return (
                    <tr key={index}>
                      <td>{complicationEvent.customPatientId}</td>
                      <td>{STUDY_ABBREVIATION_MAP[complicationEvent.study]} ({complicationEvent.treatmentSide[0]})</td>
                      <td>{moment(complicationEvent.eventDate).format("DD/MM/YYYY")}</td>
                      <td>
                        <Link to={`/patients/${complicationEvent.customPatientId}/events/complication/${complicationEvent.eventId}/edit`}>View Complication</Link></td>
                      <td className='action-buttons'>
                        <div>
                          <Button variant="primary" onClick={() => navigate(`/patients/${complicationEvent.customPatientId}`)}><MdExitToApp /></Button>
                        </div>
                      </td>
                    </tr>
                  )
                }) : <tr><td colSpan={100} style={{textAlign: "center"}}>100% compliance rate</td></tr>}
              </tbody>
            </Table>
          </div>
        </>)}    
    </div>
  )
}

export default PatientComplications