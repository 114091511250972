import React, { useEffect, useState} from 'react'
import axios from 'axios'
import { BASE_URL, STUDY_ABBREVIATION_MAP } from '../../utils/constants';
import { FaBell, FaBoxArchive, FaPaperPlane, FaTrashCan } from "react-icons/fa6";
import { MdExitToApp } from "react-icons/md";
import { Table, Button } from 'react-bootstrap'
import { Oval } from "react-loader-spinner"
import Modal from 'react-bootstrap/Modal'
import { useNavigate, Link } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import _ from "underscore"
import { AiFillCaretUp, AiFillCaretDown } from "react-icons/ai";

import "./Notifications.scss"

const Notifications = ({ user, logUserOut, setNumberOfNotifications }) => {
  const [loading, setLoading] = useState(true)
  const [surveyOutliers, setSurveyOutliers] = useState([])
  const [outlierToArchive, setOutlierToArchive] = useState({})
  const [termsAndConditionsRejectedSurveys, setTermsAndConditionsRejectedSurveys] = useState([])
  const [termsAndConditionsRejectedSurvey, setTermsAndConditionsRejectedSurvey] = useState({})
  const [showArchiveModal, setShowArchiveModal] = useState("");
  const [showResendSurveyModal, setShowResendSurveyModal] = useState("");
  const [showDeletePatientModal, setShowDeletePatientModal] = useState("");
  const [sorting, setSorting] = useState({ field: 'alertDate', ascending: false })
  const navigate = useNavigate();
  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  }

  useEffect(() => {
    const getNotifications = async () => {
      try {
        let response = await axios.get(`${BASE_URL}/api/v1/self-assessment/outliers/`, config)
        const outliers = response.data.surveyOutliers
        setSurveyOutliers(outliers)

        response = await axios.get(`${BASE_URL}/api/v1/self-assessment/terms-and-conditions-rejected/`, config)
        const surveys = response.data.termsAndConditionsRejectedSurveys
        setTermsAndConditionsRejectedSurveys(surveys)
        
        setNumberOfNotifications(outliers.length + surveys.length)
        setLoading(false)
      }
      catch (error) {
        console.log(error)
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        if (error.response && (error.response.status === 401 || message === 'Not authorized, token failed')) {
          logUserOut()
        }
      }
    }
    getNotifications()
  }, [])

  useEffect(() => {
    setSurveyOutliers(sorting.ascending? _.sortBy(surveyOutliers, sorting.field) : _.sortBy(surveyOutliers, sorting.field).reverse())
    setTermsAndConditionsRejectedSurveys(sorting.ascending? _.sortBy(termsAndConditionsRejectedSurveys, sorting.field) : _.sortBy(termsAndConditionsRejectedSurveys, sorting.field).reverse())
  }, [sorting]);

  const handleArchive = async () => {
    try {
      const body = {
        vasId: outlierToArchive.vasId
      }
      setShowArchiveModal(false)
      await axios.post(`${BASE_URL}/api/v1/self-assessment/outliers/handle`, body, config)
      setSurveyOutliers(previousSurveyOutliers => previousSurveyOutliers.filter(surveyOutlier => surveyOutlier.vasId !== outlierToArchive.vasId))
      setNumberOfNotifications(previousNumberOfNotifications => previousNumberOfNotifications - 1)
    }
    catch (error) {
      console.log(error)
      const message = error.response && error.response.data.message ? error.response.data.message : error.message
      if (error.response && (error.response.status === 401 || message === 'Not authorized, token failed')) {
        logUserOut()
      }
    }
  }

  const handleResendSurvey = async () => {
    try {
      const body = {
        customPatientId: termsAndConditionsRejectedSurvey.customPatientId,
        eventId: termsAndConditionsRejectedSurvey.eventId,
        study: termsAndConditionsRejectedSurvey.study,
        treatmentSide: termsAndConditionsRejectedSurvey.treatmentSide
      }
      setShowResendSurveyModal(false)
      await axios.post(`${BASE_URL}/api/v1/event/${termsAndConditionsRejectedSurvey.eventId}/survey/resend-pre-treatment-survey`, body, config)
      toast.success('Resend pre-treatment survey!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
      setTermsAndConditionsRejectedSurveys(previousTermsAndConditionsRejectedSurveys => previousTermsAndConditionsRejectedSurveys.filter(survey => survey.customPatientId !== termsAndConditionsRejectedSurvey.customPatientId))
      setNumberOfNotifications(previousNumberOfNotifications => previousNumberOfNotifications - 1)
    }
    catch (error) {
      console.log(error)
      const message = error.response && error.response.data.message ? error.response.data.message : error.message
      if (error.response && (error.response.status === 401 || message === 'Not authorized, token failed')) {
        logUserOut()
      }
    }
  }

  const handleDeletePatient = async () => {
    try {
      setShowDeletePatientModal(false)
      await axios.delete(`${BASE_URL}/api/v1/patient/${termsAndConditionsRejectedSurvey.customPatientId}`, config)
      toast.success(`Patient ${termsAndConditionsRejectedSurvey.customPatientId} has been deleted`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
      setTermsAndConditionsRejectedSurveys(previousTermsAndConditionsRejectedSurveys => previousTermsAndConditionsRejectedSurveys.filter(survey => survey.customPatientId !== termsAndConditionsRejectedSurvey.customPatientId))
      setNumberOfNotifications(previousNumberOfNotifications => previousNumberOfNotifications - 1)
    }
    catch (error) {
      console.log(error)
      const message = error.response && error.response.data.message ? error.response.data.message : error.message
      if (error.response && (error.response.status === 401 || message === 'Not authorized, token failed')) {
        logUserOut()
      }
    }
  }


  return (
    <div className="notifications">
      {loading ? (<Oval
          height={80}
          width={80}
          color="#4caeff"
          wrapperStyle={{}}
          wrapperClass="loader"
          visible={true}
          ariaLabel='oval-loading'
          secondaryColor="#4caeff"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
        ) : (
        <>
          <Modal show={showArchiveModal} onHide={() => setShowArchiveModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Please confirm action</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to archive outlier (VAS = {outlierToArchive.vasScore}) from {outlierToArchive.customPatientId}?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowArchiveModal(false)}>
                Back
              </Button>
              <Button variant="primary" onClick={handleArchive}>
                Archive Outlier
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={showResendSurveyModal} onHide={() => setShowResendSurveyModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Please confirm action</Modal.Title>
            </Modal.Header>
            <Modal.Body>Do you want to resend the pre-treatment survey to patient {termsAndConditionsRejectedSurvey.customPatientId}?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowResendSurveyModal(false)}>
                Back
              </Button>
              <Button variant="primary" onClick={handleResendSurvey}>
                Resend Survey
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={showDeletePatientModal} onHide={() => setShowDeletePatientModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Please confirm action</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete patient {termsAndConditionsRejectedSurvey.customPatientId}?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowDeletePatientModal(false)}>
                Back
              </Button>
              <Button variant="danger" onClick={handleDeletePatient}>
                Delete Patient
              </Button>
            </Modal.Footer>
          </Modal>

          <div className="notifications-header">
            <h2 className="logo"><FaBell /> Notifications</h2> 
          </div>
          <div className='notifications-table'>
            <Table striped bordered={false} hover>
              <thead>
                <tr>
                  <th className='alert-date' onClick={() => setSorting({field: "alertDate", ascending: !sorting.ascending})}>Alert Date {sorting.field === "alertDate"? sorting.ascending? <AiFillCaretUp/>: <AiFillCaretDown/> : ""}</th>
                  <th className='custom-id' onClick={() => setSorting({field: "customPatientId", ascending: !sorting.ascending})}>Custom Patient ID {sorting.field === "customPatientId"? sorting.ascending? <AiFillCaretUp/>: <AiFillCaretDown/> : ""}</th>
                  <th className='study'>Study</th>
                  <th className='alert-details'>Alert Details</th>
                  <th className='milestone'>Milestone</th>
                  <th className='actions'>Actions</th>
                  <th className='notification-action-buttons'></th>
                </tr>
              </thead>
              <tbody>
                {surveyOutliers.length > 0 ? surveyOutliers.map((surveyOutlier, index) => {
                  return (
                    <tr key={index}>
                      <td>{moment(surveyOutlier.alertDate).format("DD/MM/YYYY")}</td>
                      <td>{surveyOutlier.customPatientId}</td>
                      <td>{STUDY_ABBREVIATION_MAP[surveyOutlier.study]} ({surveyOutlier.treatmentSide[0]})</td>
                      <td>VAS = {surveyOutlier.vasScore}</td>
                      <td>{surveyOutlier.stage}</td>
                      <td><Link to={`/patient/${surveyOutlier.customPatientId}/event/${surveyOutlier.eventId}/${surveyOutlier.study.replace(" ", "-")}/${surveyOutlier.treatmentSide.toLowerCase()}/survey/${surveyOutlier.stage.replace(" ", "-")}/edit`}>View Survey</Link></td>
                      <td className='notification-action-buttons'>
                        <div>
                          <Button variant="warning" onClick={() => {setOutlierToArchive(surveyOutliers[index]); setShowArchiveModal(true)}}><FaBoxArchive /></Button>
                          <Button variant="primary" onClick={() => navigate(`/patients/${surveyOutlier.customPatientId}`)}><MdExitToApp /></Button>
                        </div>
                      </td>
                    </tr>
                  )
                }) : <tr><td colSpan={100} style={{textAlign: "center"}}>No active notifications</td></tr>}
              </tbody>
            </Table>
          </div>
          <div className='notifications-table'>
            <Table striped bordered={false} hover>
              <thead>
                <tr>
                  <th className='alert-date' onClick={() => setSorting({field: "alertDate", ascending: !sorting.ascending})}>Alert Date {sorting.field === "alertDate"? sorting.ascending? <AiFillCaretUp/>: <AiFillCaretDown/> : ""}</th>
                  <th className='custom-id' onClick={() => setSorting({field: "customPatientId", ascending: !sorting.ascending})}>Custom Patient ID {sorting.field === "customPatientId"? sorting.ascending? <AiFillCaretUp/>: <AiFillCaretDown/> : ""}</th>
                  <th className='study'>Study</th>
                  <th className='alert-details'>Alert Details</th>
                  <th className='milestone'>Surgery Date</th>
                  <th className='actions'>Actions</th>
                  <th className='terms-and-condition-action-buttons'></th>
                </tr>
              </thead>
              <tbody>
                {termsAndConditionsRejectedSurveys.length > 0 ? termsAndConditionsRejectedSurveys.map((survey, index) => {
                  return (
                    <tr key={index}>
                      <td>{moment(survey.alertDate).format("DD/MM/YYYY")}</td>
                      <td>{survey.customPatientId}</td>
                      <td>{STUDY_ABBREVIATION_MAP[survey.study]} ({survey.treatmentSide[0]})</td>
                      <td>T&C rejected</td>
                      <td>{moment(survey.eventDate).format("DD/MM/YYYY")}</td>
                      <td><Link to={`/patient/${survey.customPatientId}/event/${survey.eventId}/${survey.study.replace(" ", "-")}/${survey.treatmentSide.toLowerCase()}/survey/pre-treatment/edit`}>View Survey</Link></td>
                      <td className='terms-and-condition-action-buttons'>
                        <div>
                          <Button variant="success" onClick={() => {setTermsAndConditionsRejectedSurvey(survey); setShowResendSurveyModal(true)}}><FaPaperPlane /></Button>
                          <Button variant="danger" onClick={() => {setTermsAndConditionsRejectedSurvey(survey); setShowDeletePatientModal(true)}}><FaTrashCan /></Button>
                          <Button variant="primary" onClick={() => navigate(`/patients/${survey.customPatientId}`)}><MdExitToApp /></Button>
                        </div>
                      </td>
                    </tr>
                  )
                }) : <tr><td colSpan={100} style={{textAlign: "center"}}>No active notifications</td></tr>}
              </tbody>
            </Table>
          </div>
        </>)}    
    </div>
  )
}

export default Notifications