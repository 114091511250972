import React, { useEffect, useState} from 'react'
import axios from 'axios'
import { Table, Form, Button } from 'react-bootstrap'
import { Oval } from "react-loader-spinner"
import { BiTask } from "react-icons/bi";
import { MdExitToApp } from "react-icons/md";
import { BASE_URL } from '../../utils/constants';
import { Tooltip } from 'react-tooltip'
import { useNavigate, Link } from 'react-router-dom';
import { MdOutlinePhoneIphone } from "react-icons/md";
import { GoMail } from "react-icons/go";
import moment from 'moment'
import { toast } from 'react-toastify';
import CircleProgressBar from '../../components/CircleProgressBar/CircleProgressBar';

import './Dashboard.scss'

const Dashboard = ({ user, logUserOut }) => {
  const [loading, setLoading] = useState(true)
  const [timeInterval, setTimeInterval] = useState(14)
  const [pendingTasks, setPendingTasks] = useState([])
  const [patientDetails, setPatientDetails] = useState({})
  const [surveyUrl, setSurveyUrl] = useState("")
  const [compliancePercentage , setCompliancePercentage] = useState(0.00)
  const [complicationPercentage , setComplicationPercentage] = useState(0.00)
  const navigate = useNavigate();

  const getPatientPendingTasksWithinTimeInterval = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    }
    try {
      const response = await axios.get(`${BASE_URL}/api/v1/pending-task/within-time-interval/${timeInterval}`, config)
      setPendingTasks(response.data.pendingTasks.map((pendingTask, index) => {
        let milestone = ""
        let amount = ""
        if(pendingTask.current_milestone.split(" ").length > 1) {
          [amount, milestone] = pendingTask.current_milestone.split(" ")
        }
        else {
          milestone = pendingTask.current_milestone
        }
        const study = pendingTask.study === "SAS"? "shoulder-arthroscopy" : pendingTask.study === "SAP"? "shoulder-arthroplasty" : 'elbow'
        return {
          ...pendingTask,
          surveyUrl: `/patient/${pendingTask.custom_patient_id}/event/${pendingTask.event_id}/${study}/${pendingTask.treatment_side.toLowerCase()}/self-assessment/${milestone}${milestone !== "pre-treatment"? `/${amount}` : ""}`
        }
      }))
      setLoading(false)
    }
    catch (error) {
      console.log(error)
      const message = error.response && error.response.data.message ? error.response.data.message : error.message
      if (error.response && (error.response.status === 401 || message === 'Not authorized, token failed')) {
        logUserOut()
      }
    }
  }
  
  useEffect(() => {
    getPatientPendingTasksWithinTimeInterval()
  }, [timeInterval])

  useEffect(() => {
    const getCompliancePercentages = async () => {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
      try {
        const response = await axios.get(`${BASE_URL}/api/v1/patient/compliance-percentages`, config)
        setCompliancePercentage(response.data.compliancePercentage)
        setComplicationPercentage(response.data.complicationPercentage)
      }
      catch (error) {
        console.log(error)
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        if (error.response && (error.response.status === 401 || message === 'Not authorized, token failed')) {
          logUserOut()
        }
      }
    }
    getCompliancePercentages()
  }, [])

  const sendReminder = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    }
    const body = {
      milestone: patientDetails.milestone,
      email: patientDetails.email,
      surveyUrl: patientDetails.surveyUrl,
      eventId: patientDetails.eventId,
      customPatientId: patientDetails.customPatientId,
    }
    try {
      const response = await axios.post(`${BASE_URL}/api/v1/pending-task/send-reminder`, body, config)
      toast.success('Reminder sent!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }
    catch(error) {
      console.log()
      toast.error('Failed to send reminder!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }
    
  }

  return (
    <div className="dashboard">
      {loading ? (<Oval
          height={80}
          width={80}
          color="#4caeff"
          wrapperStyle={{}}
          wrapperClass="loader"
          visible={true}
          ariaLabel='oval-loading'
          secondaryColor="#4caeff"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
        ) : (
        <>
          <div className="dashboard-header">
            <div>
              <h2 className="logo"><BiTask /> Pending Patient Tasks</h2> 
              <div className="compliance-scores">
                <div className='progress-bar'>
                  <CircleProgressBar percentage={compliancePercentage} colour="rgb(50, 205, 50)" width="100px" height="100px" radius="35" onClickAction={() => navigate(`/patient-compliance`)}/>
                  <p>Patient Compliance</p>
                </div>
                <div className='progress-bar'>
                  <CircleProgressBar percentage={complicationPercentage} colour="rgb(50, 205, 50)" width="100px" height="100px" radius="35" onClickAction={() => navigate(`/patient-complications`)}/>
                  <p>Complications</p>
                </div>
              </div>
            </div>
            <Form.Group className="form-group time-interval">
              <Form.Label>Show tasks due within</Form.Label>
              <Form.Select
                value={timeInterval}
                onChange={(event) => setTimeInterval(event.target.value)}>
                <option value="7">1 week</option>
                <option value="14">2 weeks</option>
                <option value="30">1 month</option>
              </Form.Select>
            </Form.Group>
          </div>
          <div className='dashboard-table'>
            <Table striped bordered={false} hover>
              <thead>
                <tr>
                  <th className='when'>When</th>
                  <th className='deadline'>Deadline</th>
                  <th className='custom-id'>Custom Patient ID</th>
                  <th className='study'>Study</th>
                  <th className='event-date'>Procedure Date</th>
                  <th className='milestone'>Milestone</th>
                  <th className='status'>Status</th>
                  <th className='button'></th>
                </tr>
              </thead>
              <tbody>
                {pendingTasks.map((pendingTask, index) => {
                  return (
                    <tr key={index}>
                      <td className="when">
                        {
                        pendingTask.days_until_survey_deadline === 0 ? "Due Today" : 
                        pendingTask.days_until_survey_deadline > 0 ? `Upcoming - ${pendingTask.days_until_survey_deadline} day${pendingTask.days_until_survey_deadline > 1? "s" : ""}` :
                        `Overdue - ${Math.abs(pendingTask.days_until_survey_deadline)} day${Math.abs(pendingTask.days_until_survey_deadline) > 1? "s" : ""}`
                        }
                      </td>
                    <td className="deadline">{moment(pendingTask.survey_deadline).format("DD/MM/YYYY")}</td>
                      <td className="custom-id">{pendingTask.custom_patient_id}</td>
                      <td className="study">{pendingTask.study}</td>
                      <td className="event-date">{moment(pendingTask.event_date).format("DD/MM/YYYY")}</td>
                      <td className='milestone'>{pendingTask.current_milestone}</td>
                      <td className='status' onMouseOver={() => {
                        setPatientDetails({
                          customPatientId: pendingTask.custom_patient_id,
                          eventId: pendingTask.event_id,
                          milestone: pendingTask.current_milestone, 
                          email: pendingTask.email, 
                          cellPhoneNumber: pendingTask.cell_phone_number, 
                          numberOfRemindersSent: pendingTask.number_of_reminders_sent, 
                          lastReminderSent: pendingTask.last_reminder_sent_timestamp,
                          surveyUrl: pendingTask.surveyUrl
                        })}}>
                        <span className={pendingTask.days_until_survey_deadline < 0? "purple-dot" : pendingTask.days_until_survey_deadline <= 5? "red-dot" : "yellow-dot"} data-tooltip-id="status-tooltip" data-tooltip-offset={15}>!</span>
                      </td>
                      <td className="button"><Button variant="primary" onClick={() => navigate(`/patients/${pendingTask.custom_patient_id}`)}>
                        <MdExitToApp />
                      </Button></td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
          <Tooltip id="status-tooltip" clickable className='status-tooltip' border={true} place='left'>
            <div className="tooltip-body">
              <div className="open-survey">
                <h5 className="decorated"><span>Open survey</span></h5>
                <Link to={patientDetails.surveyUrl} target="_blank" rel="noopener noreferrer">
                  <Button variant="primary">Go to survey</Button>
                </Link>
              </div>
              <hr className='divider'/>
              <div className='survey-details'>
                <div className="reminder-details">
                  <Button variant="warning" onClick={sendReminder}>Send reminder</Button>
                  <p>{patientDetails.numberOfRemindersSent} sent. Last sent {patientDetails.lastReminderSent? moment(patientDetails.lastReminderSent).format("DD/MM/YYYY") : "n/a"}</p>
                </div>
                <hr className='divider'/>
                <div className="patient-details">
                  <p><GoMail /> {patientDetails.email}</p>
                  <p><MdOutlinePhoneIphone />{patientDetails.cellPhoneNumber}</p>
                </div>
              </div>
            </div>
          </Tooltip>
        </>)}    
    </div>
  )
}

export default Dashboard