import { React, useState, useEffect} from 'react'
import validator from 'validator';
import axios from 'axios'
import moment from 'moment'
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button } from "react-bootstrap";
import { Oval } from "react-loader-spinner"
import { toast } from 'react-toastify';
import Accordion from 'react-bootstrap/Accordion'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import Modal from 'react-bootstrap/Modal'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { RiSurveyFill } from "react-icons/ri";

import VAS from "../../components/SelfAssessment/VAS/VAS";
import ASESElbow from '../../components/SelfAssessment/ASESElbow/ASESElbow';
import ASESShoulder from '../../components/SelfAssessment/ASESShoulder/ASESShoulder';
import SANE from "../../components/SelfAssessment/SANE/SANE";
import VR12 from '../../components/SelfAssessment/VR12/VR12';
import StandardPreOp from '../../components/SelfAssessment/StandardPreOp/StandardPreOp';
import { BASE_URL, STUDY_ABBREVIATION_MAP } from "../../utils/constants";

import './EditPreTreatmentSurvey.scss'
import StandardLatePostOp from '../../components/SelfAssessment/StandardLatePostOp/StandardLatePostOp';
import StandardEarlyPostOp from '../../components/SelfAssessment/StandardEarlyPostOp/StandardEarlyPostOp';

const EditPreTreatmentSurvey = ({ user, logUserOut, createNewSurvey}) => {
  const [termsAndConditionsStatus, setTermsAndConditionsStatus] = useState(true)
  const [newSurvey, setNewSurvey] = useState(createNewSurvey)
  const [loading, setLoading] = useState(!newSurvey)
  const [disableButton, setDisableButton] = useState(false)
  const [validated, setValidated] = useState(false);
  const { eventId, customPatientId } = useParams();
  const [patient, setPatient] = useState(null)
  const [dateOfSubmission, setDateOfSubmission] = useState(moment(new Date()).format("YYYY-MM-DD"))
  const treatmentSide = useParams()["treatmentSide"]
  const stage = useParams()["stage"]
  const urlStudy = useParams()["study"]
  const [initialEmail, setInitialEmai] = useState(true)
  const [showModal, setShowModal] = useState("");
  const handleClose = () => setShowModal("");
  const study = urlStudy.replace("-", " ")
  const navigate = useNavigate();
  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  }
  const [formData, setFormData] = useState({
    vasId: null,
    saneId: null,
    asesId: null,
    vr12Id: null,
    spreopId: null,
    sepostopId: null,
    slpostopId: null,
    vasScore: "",
    saneScore: "",
    ases: {
      asesQuestion1: "",
      asesQuestion2: "",
      asesQuestion3: "",
      asesQuestion4: "",
      asesQuestion5: "",
      asesQuestion6: "",
      asesQuestion7: "",
      asesQuestion8: "",
      asesQuestion9: "",
      asesQuestion10: "",
      asesQuestion11: "",
      asesQuestion12: "",
      asesQuestion13: "",
      asesQuestion14: "",
      asesQuestion15: "",
      asesQuestion16: "",
      asesQuestion17: "",
      asesQuestion18: ""
    },
    vr12: {
      vr12Question1: "",
      vr12Question2: "",
      vr12Question3: "",
      vr12Question4: "",
      vr12Question5: "",
      vr12Question6: "",
      vr12Question7: "",
      vr12Question8: "",
      vr12Question9: "",
      vr12Question10: "",
      vr12Question11: "",
      vr12Question12: "",
      vr12Question13: "",
      vr12Question14: ""
    },
    spreop: {
      spreopQuestion1: "",
      spreopQuestion2: "",
      spreopQuestion3: "",
      spreopQuestion4: ""
    },
    sepostop: {
      sepostopQuestion1: "",
      sepostopQuestion2: "",
      sepostopQuestion3: "",
    },
    slpostop: {
      slpostopQuestion1: "",
      slpostopQuestion2: "",
      slpostopQuestion3: "",
      slpostopQuestion4: ""
    }
  })

  useEffect(() => {
    const getSurveyByEventId = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/v1/event/${eventId}/survey/${stage}`, config)
        const survey = response.data.survey
        if (!createNewSurvey && !survey.termsAndConditionsAccepted) {
          setNewSurvey(true)
        }
        setFormData(survey)
        setDateOfSubmission(moment(survey.dateOfSubmission).format("YYYY-MM-DD"))
        setLoading(false)
      }
      catch (error) {
        console.log(error)
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        if (error.response && (error.response.status === 401 || message === 'Not authorized, token failed')) {
          logUserOut()
        }
      }
    }

    const getPatientInfo = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/v1/patient/${customPatientId}`, config)
        const patient = response.data.patient
        setPatient(patient)
        setLoading(false)
      }
      catch (error) {
        console.log(error)
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        if (error.response && (error.response.status === 401 || message === 'Not authorized, token failed')) {
          logUserOut()
        }
      }
    }
    
    if(newSurvey) {
      getPatientInfo()
    }
    else {
      getSurveyByEventId()
    }
  }, [])


  const handleTermsAndConditionsAccept = () => {
    setTermsAndConditionsStatus({
      completed: true,
      accepted: true
    })
  }

  const handleTermsAndConditionsReject = async () => {
    setTermsAndConditionsStatus({
      completed: true,
      accepted: false
    })
    const data = {
      customPatientId: customPatientId,
      eventId: eventId
    }
    try {
      await axios.post(`${BASE_URL}/api/v1/self-assessment/terms-and-conditions-rejected`, data)
    }
    catch (error) {
      console.log(error)
    }
  }
  
  const handleInputData = (type, input, value) => {
    switch(type) {
      case "ases":
        setFormData(prevState => {
          prevState.ases[input] = value
          return{...prevState}
        });
        break; 
      case "vr12":
        setFormData(prevState => {
          prevState.vr12[input] = value
          return{...prevState}
        });
        break;
      case "spreop":
        setFormData(prevState => {
          prevState.spreop[input] = value
          return{...prevState}
        });
        break;
      case "sepostop":
        setFormData(prevState => {
          prevState.sepostop[input] = value
          return{...prevState}
        });
        break;
      case "slpostop":
        setFormData(prevState => {
          prevState.slpostop[input] = value
          return{...prevState}
        });
        break;
      default:
        setFormData(prevState => {
          prevState[input] = value
          return{...prevState}
        });
    } 
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formError = validateCurrentForm()
    if(!formError) {
      setValidated(false)
      const data = {
        eventId: eventId,
        customPatientId: customPatientId,
        study: study,
        termsAndConditionsAccepted: termsAndConditionsStatus.accepted,
        formAnswers: formData,
        dateOfSubmission: moment(dateOfSubmission).format("YYYY-MM-DD"),
      }
      try {
        setDisableButton(true)
        if(newSurvey) {
          switch(stage) {
            case "pre-treatment":
              await axios.post(`${BASE_URL}/api/v1/self-assessment/pre-treatment`, data, config)
              break;
            case "2-weeks": case "6-weeks":
              await axios.post(`${BASE_URL}/api/v1/self-assessment/week/${stage[0]}`, data, config)
              break;
            case "3-months": case "6-months":
              await axios.post(`${BASE_URL}/api/v1/self-assessment/month/${stage[0]}`, data, config)
              break;
            default:
              await axios.post(`${BASE_URL}/api/v1/self-assessment/year/${stage[0]}`, data, config)
          }
        }
        else {
          await axios.put(`${BASE_URL}/api/v1/event/${eventId}/survey/${stage}`, data, config)
        }
        toast.success(newSurvey? 'Survey saved!' : "Survey updated!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
        navigate(-1)
      }
      catch(error) {
        console.log(error.response.data)
        toast.error(newSurvey? 'Error during saving survey!' : "Could not update survey!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      }
      setDisableButton(false)
    }
    else {
      setValidated(true)
    }
  };

  const validateCurrentForm = () => {
    if(validator.isEmpty(formData.vasScore.toString()) || !validator.isFloat(formData.vasScore.toString(), { min: 0, max: 10 })) {
      return true
    }

    if((["elbow", "shoulder arthroscopy"].includes(study) && !["2-weeks", "6-weeks"].includes(stage)) ||
    (study === "shoulder arthroplasty" && !["2-weeks", "6-weeks", "3-months"].includes(stage))) {
      const j = (study === "elbow"? 18 : 17)
        for(let i = 1; i <= j; i++) {
          if(validator.isEmpty(formData.ases[`asesQuestion${i}`].toString())){
            return true
          }
          if(study !== "elbow" && i === 5 && !validator.isInt(formData.ases[`asesQuestion${i}`].toString(), { min: 0, max: 20 })) {
            return true
          }
          if(study !== "elbow" && i === 7 && !validator.isFloat(formData.ases[`asesQuestion${i}`].toString(), { min: 0, max: 10 })) {
            return true
          }
          if(study === "elbow" && [2, 3, 4, 5, 6].includes(i) && !validator.isInt(formData.ases[`asesQuestion${i}`].toString(), { min: 0, max: 10 })) {
            return true
          }
        }
    }

    if((["elbow", "shoulder arthroscopy"].includes(study) && !["2-weeks", "6-weeks"].includes(stage)) ||
    (study === "shoulder arthroplasty" && !["2-weeks", "6-weeks", "3-months"].includes(stage))) {
      if(validator.isEmpty(formData.saneScore.toString()) || !validator.isInt(formData.saneScore.toString(), { min: 0, max: 100 })) {
        return true
      }
    }

    if(stage === "pre-treatment") {
      for(let i = 1; i <= 4; i++) {
        if(validator.isEmpty(formData.spreop[`spreopQuestion${i}`])){
          return true;
        }
      }
    }
  
    if(!["2-weeks", "6-weeks", "3-months"].includes(stage)) {
      for(let i = 1; i <= 14; i++) {
        if(validator.isEmpty(formData.vr12[`vr12Question${i}`])){
          return true 
        }
      }
    }
    
    if(!["pre-treatment", "2-weeks", "6-weeks", "3-months", "6-months"].includes(stage)) {
      for(let i = 1; i <= 4; i++) {
        if(validator.isEmpty(formData.slpostop[`slpostopQuestion${i}`])){
          return true;
        }
      }
    }

    if(stage === "2-weeks") {
      for(let i = 1; i <= 3; i++) {
        if(validator.isEmpty(formData.sepostop[`sepostopQuestion${i}`])){
          return true
        }
      }
    }
    return false
  }

  const handleDeleteSurvey = async () => {
    const body = {
      eventId: eventId,
      study: study,
      formIds: {
        vasId: formData.vasId,
        saneId: formData.saneId,
        asesId: formData.asesId,
        vr12Id: formData.vr12Id,
        spreopId: formData.spreopId,
        sepostopId: formData.sepostopId,
        slpostopId: formData.slpostopId,
      },
    }
    console.log(body)
    try {
      await axios.post(`${BASE_URL}/api/v1/event/${eventId}/survey/${stage}/delete`, body, config)
      toast.success('Survey deleted!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
      handleClose()
      navigate(-1)
    }
    catch(error) {
      console.log(error.response.data)
      toast.error('Error during survey deletion!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const handleResetSurvey = async () => {
    const body = {
      eventId: eventId,
      study: study,
      formIds: {
        vasId: formData.vasId,
        saneId: formData.saneId,
        asesId: formData.asesId,
        vr12Id: formData.vr12Id,
        spreopId: formData.spreopId,
        sepostopId: formData.sepostopId,
        slpostopId: formData.slpostopId,
      },
    }
    try {
      await axios.post(`${BASE_URL}/api/v1/event/${eventId}/survey/${stage}/reset`, body, config)
      toast.success('Survey deleted!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
      handleClose()
      navigate(-1)
    }
    catch(error) {
      console.log(error.response.data)
      toast.error('Error during survey deletion!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const handleSendEmail = async () => {
    const [stageValue, stageUnit] = stage.split("-")
    const urlStage = stage === "pre-treatment"? "pre-treatment" : `${stageUnit.replace("s", "")}/${stageValue}`
    const config = { headers: { Authorization: `Bearer ${user.token}`, },}
    const body = {
      milestone: stage === "pre-treatment"? "pre-treatment" : stage.replace("-", " "),
      email: patient.email,
      surveyUrl: `/patient/${customPatientId}/event/${eventId}/${urlStudy}/${treatmentSide.toLowerCase()}/self-assessment/${urlStage}`,
      eventId: eventId,
      customPatientId: customPatientId,
    }
    try {
      if (initialEmail) {
        await axios.post(`${BASE_URL}/api/v1/pending-task/resend-initial-email`, body, config)
      }
      else {
        await axios.post(`${BASE_URL}/api/v1/pending-task/send-reminder`, body, config)
      }
      toast.success(initialEmail? 'Email sent!' : "Reminder sent!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }
    catch(error) {
      toast.error(initialEmail? "Failed to send email!" : "Failed to send reminder!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }
    handleClose()
  }

  return (
    <div className="edit-survey">
    { loading? (
      <Oval
        height={80}
        width={80}
        color="#4caeff"
        wrapperStyle={{}}
        wrapperClass="loader"
        visible={true}
        ariaLabel='oval-loading'
        secondaryColor="#4caeff"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    ) : (
      <>
        <Modal show={showModal === "email"} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>You are about to send out a new {initialEmail? "email" : "reminder"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to {initialEmail? "resend the initial survey email" : "send a survey reminder email"}?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button variant="success" onClick={handleSendEmail}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showModal === "delete" || showModal === "reset"} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>You are about to {showModal === "delete"? "delete" : "reset"} a survey</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to {showModal === "delete"? "delete" : "reset"} this survey?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Back
            </Button>
            <Button variant="danger" onClick={showModal === "delete"? handleDeleteSurvey : handleResetSurvey}>
              {showModal === "delete"? "Delete" : "Reset"}
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="edit-survey-header">
          <div className="survey-title">
            <h2><RiSurveyFill /> Survey</h2>
            <div className='buttons'> 
              <Button onClick={() => navigate(-1)}>Back</Button>
            </div>
          </div>
          <Breadcrumb>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/patients" }}>Patients</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/patients/${customPatientId}` }}>{customPatientId}</Breadcrumb.Item>
            <Breadcrumb.Item active>{`${stage === "pre-treatment"? "Pre-treatment" : stage.charAt(0).toUpperCase() + stage.replace("-", " ").slice(1)} survey ${STUDY_ABBREVIATION_MAP[study]} (${treatmentSide.toUpperCase().charAt(0)})`}</Breadcrumb.Item>
          </Breadcrumb>
        </div>           
        <div className="survey-details">
          <div className="survey-details-header">
            <h4>Survey forms</h4>
            <div className="survey-buttons">
              <Dropdown>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                  Actions
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {
                    newSurvey && (
                      <>
                        <Dropdown.Item onClick={() => {setInitialEmai(true); setShowModal("email")}}>Resend survey email</Dropdown.Item>
                        <Dropdown.Item onClick={() => {setInitialEmai(false); setShowModal("email")}}>Send survey reminder</Dropdown.Item>
                        <Dropdown.Divider />
                      </>
                    )
                  }
                  {!newSurvey && <Dropdown.Item onClick={() => {setShowModal("reset")}}>Reset survey</Dropdown.Item>}
                  <Dropdown.Item onClick={() => {setShowModal("delete")}}>Delete survey</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Button disabled={disableButton} onClick={handleSubmit}>{newSurvey? "Save" : "Update"} Survey</Button>
            </div>
          </div>
          <div>
            {
              !newSurvey && (
                <div className='date-of-submission'>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="inputGroup-sizing-default">
                      Date of submission
                    </InputGroup.Text>
                    <Form.Control
                      aria-label="Default"
                      aria-describedby="inputGroup-sizing-default"
                      type="date"
                      value={dateOfSubmission}
                      onChange={(e) => {setDateOfSubmission(e.target.value)}}
                    />
                  </InputGroup>
                </div>
              )
            }
            <Form validated={validated} onSubmit={handleSubmit}>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    VAS
                  </Accordion.Header>
                  <Accordion.Body>
                    <VAS handleFormData={handleInputData} values={formData}/>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              {((["elbow", "shoulder arthroscopy"].includes(study) && !["2-weeks", "6-weeks"].includes(stage)) ||
                (study === "shoulder arthroplasty" && !["2-weeks", "6-weeks", "3-months"].includes(stage)))  &&
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      ASES
                    </Accordion.Header>
                    <Accordion.Body>
                      {
                      study === "elbow"? (
                        <ASESElbow handleFormData={handleInputData} values={formData}/>
                      ) : (
                        <ASESShoulder handleFormData={handleInputData} values={formData}/>
                      )

                      }
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              }
              {
                ((["elbow", "shoulder arthroscopy"].includes(study) && !["2-weeks", "6-weeks"].includes(stage)) ||
                (study === "shoulder arthroplasty" && !["2-weeks", "6-weeks", "3-months"].includes(stage)))  &&
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      SANE
                    </Accordion.Header>
                    <Accordion.Body>
                      <SANE handleFormData={handleInputData} study={study} values={formData}/>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              }
              {
                stage === "pre-treatment" &&
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Standard PreOp
                    </Accordion.Header>
                    <Accordion.Body>
                      <StandardPreOp handleFormData={handleInputData} values={formData}/>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              }
              {
                !["2-weeks", "6-weeks", "3-months"].includes(stage) &&
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      VR12
                    </Accordion.Header>
                    <Accordion.Body>
                      <VR12 handleFormData={handleInputData} values={formData}/>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              }
              {
                !["pre-treatment", "2-weeks", "6-weeks", "3-months", "6-months"].includes(stage) &&
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Standard Late PostOp
                    </Accordion.Header>
                    <Accordion.Body>
                      <StandardLatePostOp handleFormData={handleInputData} values={formData}/>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              }
              {
                stage === "2-weeks" &&
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Standard Early PostOp
                    </Accordion.Header>
                    <Accordion.Body>
                      <StandardEarlyPostOp handleFormData={handleInputData} values={formData}/>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              }
            </Form>
          </div>
        </div>
      </>
    )}
    </div>
  )
}

export default EditPreTreatmentSurvey
  